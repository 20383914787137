import {
    cancelInstalmentProgramEarlyApi,
    cancelProgramApi,
    resolveNotificationsApi
} from "@/api/parentsApi";
import { atomsWithMutation } from "jotai-tanstack-query";
import { atomWithStorage, createJSONStorage } from "jotai/utils";
import { AsyncStorage } from "jotai/vanilla/utils/atomWithStorage";

import { UserLoginInfoInterface } from "@ts/interfaces/Parent/UserInterface";

const localStorageForAtom = createJSONStorage(() => localStorage);

export const [, cancelProgramAtom] = atomsWithMutation(() => ({
    mutationKey: ["cancelProgram"],
    mutationFn: async (data: {
        type: "addon" | "plan";
        licenseId?: number;
        itemId?: number;
        studentId: number;
        reasonId: number | null;
        cancellationType: "refund" | "non-refund";
        instalmentEarlyCancel: boolean;
    }) => {
        if (data.instalmentEarlyCancel)
            return (
                await cancelInstalmentProgramEarlyApi({
                    licenseId: data.licenseId!,
                    reasonId: data.reasonId
                })
            ).data;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { instalmentEarlyCancel, ...body } = data;
        return (await cancelProgramApi(body)).data;
    }
}));

export const [, resolveNotificationAtom] = atomsWithMutation(() => ({
    mutationKey: ["resolveNotification"],
    mutationFn: async (data: { notificationIds: number[] }) => {
        return (await resolveNotificationsApi(data)).data;
    }
}));

export const userLoginAtom = atomWithStorage<UserLoginInfoInterface>(
    "parentLoginInfo",
    {
        userName: "",
        rememberMe: false
    },
    localStorageForAtom as AsyncStorage<UserLoginInfoInterface>,
    { getOnInit: true }
);

// Defining debug info for the atoms created to be consumed by the Jotai DevTools
userLoginAtom.debugLabel = "userLoginAtom";
